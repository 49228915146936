import React from "react"
import Layout from '../../components/layout-id'
import Head from '../../components/head'
import '../../styles/who-we-are.css'
import { Link } from 'gatsby'
import {graphql} from 'gatsby'
import {Row, Col} from 'react-bootstrap'
import { GatsbyImage } from "gatsby-plugin-image";

import rightArrow from '../../images/long-arrow-right.png'
import NEURAFARM from '../../images/Neurafarm-Logo-Samping.png'
import logoLinkedIn from "../../images/linked-in-logo-2.png"
import Emblem from "../../images/emblem.png"

import Vegetables from '../../images/vegetables.png'
import Cabbages from '../../images/cabbage.png'
import OrangeTree from '../../images/orange_tree.png'


export const query = graphql`{
  LogoITB: file(relativePath: {eq: "logo-itb.png"}) {
    childImageSharp {
      gatsbyImageData(placeholder: NONE, layout: FULL_WIDTH)
    }
  }
  LogoLPIK: file(relativePath: {eq: "logo-lpik.png"}) {
    childImageSharp {
      gatsbyImageData(placeholder: NONE, layout: FULL_WIDTH)
    }
  }
  LogoGK: file(relativePath: {eq: "logo-gk.png"}) {
    childImageSharp {
      gatsbyImageData(placeholder: NONE, layout: FULL_WIDTH)
    }
  }
  LogoCISCO: file(relativePath: {eq: "logo-cisco-2.png"}) {
    childImageSharp {
      gatsbyImageData(placeholder: NONE, layout: FULL_WIDTH)
    }
  }
  LogoSIF: file(relativePath: {eq: "logo-SIF.png"}) {
    childImageSharp {
      gatsbyImageData(placeholder: NONE, layout: FULL_WIDTH)
    }
  }
  LogoNVIDIA: file(relativePath: {eq: "logo-nvidia.png"}) {
    childImageSharp {
      gatsbyImageData(placeholder: NONE, layout: FULL_WIDTH)
    }
  }
  LogoTELKOMSEL: file(relativePath: {eq: "logo-telkomsel.png"}) {
    childImageSharp {
      gatsbyImageData(placeholder: NONE, layout: FULL_WIDTH)
    }
  }
  LogoTFF: file(relativePath: {eq: "logo-tff.png"}) {
    childImageSharp {
      gatsbyImageData(placeholder: NONE, layout: FULL_WIDTH)
    }
  }
  LogoTINC: file(relativePath: {eq: "logo-tinc.png"}) {
    childImageSharp {
      gatsbyImageData(placeholder: NONE, layout: FULL_WIDTH)
    }
  }
  LogoRISTEK: file(relativePath: {eq: "logo-ristek.png"}) {
    childImageSharp {
      gatsbyImageData(placeholder: NONE, layout: FULL_WIDTH)
    }
  }
  FebiImg: file(relativePath: {eq: "febi.png"}) {
    childImageSharp {
      gatsbyImageData(placeholder: NONE, layout: FULL_WIDTH)
    }
  }
  LintangImg: file(relativePath: {eq: "lintang.png"}) {
    childImageSharp {
      gatsbyImageData(placeholder: NONE, layout: FULL_WIDTH)
    }
  }
}
`

class WhatWeDo extends React.Component {

  /*constructor(props){
    super(props);
    
  }*/

  render(){

    return (
      <Layout>
        <Head 
          title = "Who We Are"
          description = "Wh0 we are ?"
          image = {Emblem}
        />
        
        <div id = "wwa-page-container">
          <div id = "wwa-section-1">
              <div id = "wwa-section-1-content">
                <div id = "section-1-content-title-1">Our Mission</div>
                <div id = "section-1-content-title-2">More Precise, More Impact</div>
                <div id = "section-1-content-paragraph">
                  Kami adalah tim kecil, yang memiliki tujuan besar: meningkatkan produktivitas dan efisiensi 
                  penggunaan sumber daya di industri pertanian melalui teknologi
              </div>
            </div>
          </div>

          <div id = "wwa-section-2">
            <div id = "wwa-section-2-content">
                <div id = "section-2-content-title">
                  Memberdayakan petani dan bisnis untuk memberi makan dunia melalui teknologi.
                </div>
                <div id = "section-2-content-paragraph">
                  <p>
                      Di Neurafarm, kami percaya bahwa kekuatan inovasi dapat membantu memecahkan bahkan tantangan paling 
                      mendesak di dunia. Termasuk ketahanan pangan. Kami juga percaya bahwa dengan memberdayakan setiap 
                      inovator di seluruh rantai pasokan pertanian dengan data dan teknologi, kami dapat meningkatkan 
                      efisiensi dan produktivitas.
                  </p>
                </div>
            </div>
          </div>

          <div id = "wwa-section-3">
            <div id = "wwa-section-3-content">
              <div className = "section-title">                
                <span>Meet the</span>
                <img src = {NEURAFARM} alt = "neurafarm-logo" width = "150px"/>
                <span>Management Team</span>
              </div>

              <div id = "flip-card-container">
                <div className="flip-card flip-card-indo">
                  <div className="flip-card-inner">

                    <div className="flip-card-front">
                      <div className = "flip-card-front-img">
                        <GatsbyImage
                          image={this.props.data.FebiImg.childImageSharp.gatsbyImageData}
                          alt = "Febi-Img" />
                      </div>

                      <div className = "flip-card-front-profil">
                        <div className = "flip-card-front-profil-name">Febi Agil Ifdillah</div>
                        <div className = "flip-card-front-profil-profession">CEO/CTO</div>
                      </div>

                      <div className = "flip-card-front-footer">
                        Learn More
                        <img src = {rightArrow} id = "right-arrow" alt = "right-arrow" width = "20px"/>
                      </div>
                    </div>

                    <div className="flip-card-back">
                      <div className = "flip-card-back-header">
                        <div className = "flip-card-back-img">
                          <GatsbyImage
                            image={this.props.data.FebiImg.childImageSharp.gatsbyImageData}
                            alt = "Febi-Img" />
                        </div>
                        <div className = "flip-card-back-profil">
                          <div className = "flip-card-back-profil-name">Febi Agil Ifdillah</div>
                          <div className = "flip-card-back-profil-profession">CEO/CTO</div>
                        </div>
                      </div>

                      <div className = "flip-card-back-body">
                        <p>
                          Febi adalah CEO / CTO Neurafarm di mana ia mendorong inovasi / strategi produk untuk perusahaan 
                          dan memastikan keunggulan teknologi sebagai bagian dari komitmen Neurafarm untuk menyediakan 
                          dan mendemokratisasikan teknologi pertanian untuk membantu petani memberi makan dunia.
                        </p>

                        <p>
                          Beliau meraih gelar BS dalam Ilmu Komputer / Informatika dari Institut Teknologi Bandung dan memiliki 
                          pengalaman luas dalam membangun perangkat lunak berkualitas tinggi, model AI / Pembelajaran Mesin, 
                          dan manajemen proyek.
                        </p>

                        <a href = "https://www.linkedin.com/in/febiagil/"><img src = {logoLinkedIn} className = "linked-in-logo" alt = "logo-linkedIn"/></a>
                      </div>

                    </div>
                  </div>
                </div>

                <div className="flip-card">
                  <div className="flip-card-inner">

                    <div className="flip-card-front">
                      <div className = "flip-card-front-img">
                        <GatsbyImage
                          image={this.props.data.LintangImg.childImageSharp.gatsbyImageData}
                          alt = "Lintang-Img" />
                      </div>

                      <div className = "flip-card-front-profil">
                        <div className = "flip-card-front-profil-name">Lintang Kusuma Pratiwi</div>
                        <div className = "flip-card-front-profil-profession">Chief Agriculture & Operations</div>
                      </div>

                      <div className = "flip-card-front-footer">
                        Learn More
                        <img src = {rightArrow} id = "right-arrow" alt = "right-arrow" width = "20px"/>
                      </div>
                    </div>

                    <div className="flip-card-back">
                      <div className = "flip-card-back-header">
                        <div className = "flip-card-back-img card-khusus">
                          <GatsbyImage
                            image={this.props.data.LintangImg.childImageSharp.gatsbyImageData}
                            alt = "Lintang-Img" />
                        </div>
                        <div className = "flip-card-back-profil">
                          <div className = "flip-card-back-profil-name">Lintang Kusuma Pratiwi</div>
                          <div className = "flip-card-back-profil-profession">Chief Agriculture & Operations</div>
                        </div>
                      </div>

                      <div className = "flip-card-back-body">
                        <p>
                          Lintang mengurus R&D dalam Pertanian untuk bereksperimen dan memajukan GAP / pengetahuan untuk dibagikan dengan petani. Dia mengelola operasi sehari-hari di Neurafarm seperti upaya pengumpulan data, dan menangani kemitraan dan hubungan dengan petani.
                        </p>

                        <p>
                          Dia adalah salah satu siswa paling berprestasi di Institut Teknologi Bandung dalam program Rekayasa 
                          Pertanian, dan menerima gelar sarjana dalam bidang Teknik Pertanian pada 2018.
                        </p>

                        <a href = "https://www.linkedin.com/in/lintang-kusuma-pratiwi-7386b9106/"><img src = {logoLinkedIn} className = "linked-in-logo" alt = "logo-linkedIn"/></a>
                      </div>



                    </div>
                  </div>
                </div>
              </div>

              <div id = "wwa-section-3-footer">
                <Link to="/careers" id = "career-link">
                  Kami selalu mencari kandidat yang memenuhi syarat! Silakan kirimkan materi aplikasi Anda
                  <img id = "read-more-wwa" src = {rightArrow} alt = "right-arrow" width = "28px"/>
                </Link>
              </div>


            </div>
          </div>

          <div id = "wwa-section-4">
            <div className = "wwa-section-title">
              <img src = {NEURAFARM} alt = "neurafarm-logo" width = "150px"/>
              <span>Di dukung oleh</span>
            </div>

            <div className = "wwa-section-divider" />

            <div id = "wwa-section-4-sponsor-container">
              <div className = "wwa-sponsors">
                <div className = "wwa-sponsor-img">
                  <GatsbyImage
                    image={this.props.data.LogoITB.childImageSharp.gatsbyImageData}
                    alt = "logo-ITB" />
                </div>

                  <div className = "wwa-sponsor-img">
                    <GatsbyImage
                      image={this.props.data.LogoLPIK.childImageSharp.gatsbyImageData}
                      alt = "logo-LPIK" />
                  </div>

                  <div className = "wwa-sponsor-img">
                    <GatsbyImage
                      image={this.props.data.LogoTINC.childImageSharp.gatsbyImageData}
                      alt = "logo-TINC" />
                  </div>

                  <div className = "wwa-sponsor-img">
                    <GatsbyImage
                      image={this.props.data.LogoGK.childImageSharp.gatsbyImageData}
                      alt = "logo-GK" />
                  </div>         

                  <div className = "wwa-sponsor-img">
                    <GatsbyImage
                      image={this.props.data.LogoNVIDIA.childImageSharp.gatsbyImageData}
                      alt = "logo-NVIDIA" />
                  </div>

                  <div className = "wwa-sponsor-img">
                    <GatsbyImage
                      image={this.props.data.LogoTELKOMSEL.childImageSharp.gatsbyImageData}
                      alt = "logo-TELKOMSEL" />
                  </div>        
                  
                  <div className = "wwa-sponsor-img">
                    <GatsbyImage
                      image={this.props.data.LogoRISTEK.childImageSharp.gatsbyImageData}
                      alt = "logo-RISTEK" />
                  </div>

                  <div className = "wwa-sponsor-img">
                    <GatsbyImage
                      image={this.props.data.LogoTFF.childImageSharp.gatsbyImageData}
                      alt = "logo-TFF" />
                  </div>

                  <div className = "sponsor-img">
                    <GatsbyImage
                      image={this.props.data.LogoCISCO.childImageSharp.gatsbyImageData}
                      alt = "logo-CISCO" />
                  </div>

                  <div className = "sponsor-img">
                    <GatsbyImage
                      image={this.props.data.LogoSIF.childImageSharp.gatsbyImageData}
                      alt = "logo-SIF" />
                  </div>
                    
              </div>
            </div>
          </div>

          <div id = "wwa-section-5">
            <Row>
              <Col md = {{span : 5, offset : 1}} id = "wwa-section-5-col-1">
                <div id = "wwa-section-5-col-1-title">Share the same vision? <p>Be a part of the future of agriculture</p></div>
                
                
                <Link to = "/careers" id = "wwa-section-5-button">Join Our Team</Link>
                  
              </Col>

              <Col md = {{span : 4, offset : 2}} id = "wwa-section-5-col-2">
                  <img src = {Vegetables} alt = "vegetables-img" id = "vegetables-img"/>
                  <img src = {OrangeTree} alt = "OrangeTree-img" id = "orange-tree-img"/>
                  <img src = {Cabbages} alt = "cabbages-img" id = "cabbages-img"/>
              </Col>
            </Row>
          </div>

        </div>
      </Layout>
    ); 
  }
}

export default WhatWeDo;